<template>
  <div class="useful-links">
    <b-container class="mt-4">
      <h1 class="my-4">{{ title }}</h1>
      <ul class="row my-4">
        <template v-if="content">
          <li class="col-md-6 col-12 my-3" v-for="links in content" :key="links.title">
            <b-link rel="noopener noreferrer" class="links" :target="links.newWindow ? '_blank' : ''" :href="links.url">
              <b-card>
                <b-card-body class="text-center">
                  <b-img class="col-md-8" :src="links.logo" :alt="links.title"></b-img>
                  <ul class="list pdf col-12 col-md-10 m-auto">
                    <li class="col-12">{{ links.title }}</li>
                  </ul>
                </b-card-body>
              </b-card>
            </b-link>
          </li>
        </template>
      </ul>
    </b-container>
  </div>
</template>

<script>
import { lanToISO639, ISO639ToLan } from '@/utils'
import { mapState } from 'vuex'
import { isCSR } from '@/utils'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  components: {},
  data() {
    return {
      chartData: [],
      content: null,
      title: null
    }
  },
  serverPrefetch() {
    return this.fetchData()
  },
  mounted() {
    if (!this.alreadyInited) this.fetchData()
    // this.fetchData()
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  computed: {
    ...mapState('page', ['links']),
    ...mapState('common', ['page']),
    alreadyInited: function() {
      return !!this.menu
    },
    lang() {
      return lanToISO639(this.$getLocale())
    }
  },
  watch: {},
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id, vid } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          }),
          this.$store.dispatch('page/FETCH_LINK_LIST', {
            path,
            cookies,
            preview,
            id,
            vid
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    hanldeInit() {
      this.content = this.links
      this.title = this.page ? this.page.title : null
    },
    langName(name) {
      return name[lanToISO639(this.$route.params.lang)]
    }
  },
  beforeCreate() {},
  created() {},
  head() {
    return {
      title: '市場資訊 - 大豐銀行',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'My custom description'
        }
      ]
    }
  }
}
</script>
